.budget-section-dollar-amount {
  transition: all 300ms;
  &.negative {
    color: hsl(0, 60%, 55%);
    margin-right: -6.5px;
    &:before,
    &:after {
      color: hsl(0, 55%, 60%);
      font-weight: normal;
      top: -1px;
      position: relative;
    }
    &:before {
      content: '(';
      margin-right: 1px;
    }
    &:after {
      content: ')';
      margin-left: 1px;
    }
  }
}

// Base color variables per section
:root {
  --budget-section-border-radius: 20px;

  --budget-section-income: var(--bs-light);
  --budget-section-income-bg: hsl(129, 72%, 44%);

  --budget-section-recurring-expenses: var(--bs-light);
  --budget-section-recurring-expenses-bg: hsl(31, 93%, 51%);

  --budget-section-carryover: var(--bs-light);
  --budget-section-carryover-bg: hsl(204, 95%, 60%);

  --budget-section-recoveries: var(--bs-light);
  --budget-section-recoveries-bg: hsl(220, 93%, 54%);

  --budget-section-goals: var(--bs-light);
  --budget-section-goals-bg: hsl(280, 100%, 50%);

  --budget-section-spending: var(--bs-light);
  --budget-section-spending-bg: hsl(0, 77%, 53%);
}

// Dark mode overrides
@media (prefers-color-scheme: dark) {
  :root {
    --budget-section-income: var(--bs-dark);
    --budget-section-income-bg: hsl(129, 100%, 39%);

    --budget-section-recurring-expenses: var(--bs-dark);
    --budget-section-recurring-expenses-bg: hsl(16, 96%, 59%);

    --budget-section-carryover: var(--bs-dark);
    --budget-section-carryover-bg: hsl(204, 72%, 72%);

    --budget-section-recoveries: var(--bs-dark);
    --budget-section-recoveries-bg: hsl(214, 100%, 54%);

    --budget-section-goals: var(--bs-dark);
    --budget-section-goals-bg: hsl(280, 100%, 50%);

    --budget-section-spending: var(--bs-dark);
    --budget-section-spending-bg: hsl(0, 100%, 50%);
  }
}
